<template>
  <component is="b-card">
    <b-modal
      ref="saisie-command"
      ok-only
      :ok-title="$t('Add')"
      size="lg"
      :title="$t('Order')"
      @hidden="saisieCommandHidden"
      @ok="addProduct"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select product')" label-for="products">
            <v-select
              style="font-size: 13px"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="products"
              label="name"
              v-model="selectedProduct"
              input-id="products"
              @input="fetchProductCombinaison"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select combination')" label-for="product">
            <v-select
              style="font-size: 13px"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productCombinations"
              label="product_shortcut"
              v-model="selectedProductCombination"
              input-id="product"
              @input="fetchPrice"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!----<b-col>
          <b-form-group :label="$t('Quantity')">
            <b-form-input
              v-on:keypress="PositiveNumbersOnly"
              :placeholder="$t('Quantity')"
              type="number"
              v-model="selectedQuantity"
              :disabled="
                selectedProductCombination != null &&
                selectedProductCombination.selling_type.name === 'PAR PIECE'
              "
            />
          </b-form-group>
        </b-col>-->
        <b-col>
          <b-form-group>
            <label for="price">{{ $t("Quantity") }}</label>

            <b-input-group :append="Unity">
              <b-form-input
                v-on:keypress="PositiveNumbersOnly"
                :placeholder="$t('Quantity')"
                type="number"
                v-model="selectedQuantity"
              />
            </b-input-group>
          </b-form-group>
          <!-- </b-row> -->
        </b-col>
        <b-col>
          <b-form-group :label="$t('Piece')">
            <b-form-input
              v-on:keypress="PositiveNumbersOnly"
              :placeholder="$t('Piece')"
              type="number"
              v-model="selectedPiece"
              :disabled="
                selectedProductCombination != null &&
                selectedProductCombination.selling_type.id !== 2
              "
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="price">{{ $t("Price") }}</label>

            <b-input-group :append="Selling_Unity">
              <b-form-input
                id="price"
                :placeholder="$t('Price')"
                v-model="selectedPrice"
                type="number"
                disabled
              />
            </b-input-group>
          </b-form-group>
          <!-- </b-row> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Comment')">
            <b-form-input :placeholder="$t('Comment')" v-model="orderComment" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Select delivery day')"
          label-for="physical_depot"
          id="delivery"
        >
          <h6 style="color: red" class="etoile">*</h6>
          <flat-pickr
            v-model="selecteDeliveryDay"
            class="form-control"
            :config="{
              enableTime: false,
              minDate: new Date().toJSON().slice(0, 10),
            }"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Order type')"
          label-for="order_type"
          id="type"
        >
          <h6 style="color: red" class="etoile">*</h6>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="order_type"
            label="label"
            v-model="preparationtype"
            input-id="order_type"
            @input="selectedOrderType"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Select shipping type')"
          label-for="shipping_type"
          id="shipping"
        >
          <h6 style="color: red" class="etoile">*</h6>
          <v-select
            id="selectShipping"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="shippingTypes"
            label="name"
            v-model="selectedShippingType"
            input-id="shipping_type"
            :disabled="type === 1"
            @input="disabledOrenabledAnnexe"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Select client')"
          label-for="client"
          id="client"
        >
          <h6 style="color: red" class="etoile">*</h6>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="clients"
            v-model="selectedClient"
            label="name"
            input-id="client"
            @input="clientSelected"
            :loading="loading"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Select annexe')"
          label-for="annexes"
          id="annexe"
        >
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="annexes"
            label="name"
            v-model="selectedAnnexe"
            input-id="annexes"
            :disabled="disableSelectAnnexe"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Select seller agent')"
          label-for="seller-agent"
          id="seller-agent"
        >
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sellers"
            label="name"
            v-model="selectedSellerAgent"
            input-id="sellers"
            :disabled="disableSelectSeller"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Select emergency degree')"
          label-for="emergency_degree"
        >
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="emergencyDegrees"
            label="name"
            v-model="emergencyDegree"
            input-id="emergency_degree"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('Comment')" label-for="physical_depot">
          <b-form-input :placeholder="$t('Comment')" v-model="comment" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
         v-if="type===1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="
            !selectedClient ||
            !selectedShippingType ||
            !preparationtype ||
            !selecteDeliveryDay ||
            !selectedAnnexe ||
            !selectedSellerAgent
          "
          @click="showSaisieCommandModal"
        >
          <feather-icon icon="PlusIcon" size="16" />
          <!--{{ $t("Add product") }}-->
        </b-button>
        <b-button
          v-if="type===0 && shippingTypeId===2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="
            !selectedClient ||
            !selectedShippingType ||
            !preparationtype ||
            !selecteDeliveryDay ||
            !selectedAnnexe
          "
          @click="showSaisieCommandModal"
        >
          <feather-icon icon="PlusIcon" size="16" />
          <!--{{ $t("Add product") }}-->
        </b-button>
        <b-button
          v-if="type===0 && shippingTypeId!=2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="
            !selectedClient ||
            !selectedShippingType ||
            !preparationtype ||
            !selecteDeliveryDay
          "
          @click="showSaisieCommandModal"
        >
          <feather-icon icon="PlusIcon" size="16" />
          <!--{{ $t("Add product") }}-->
        </b-button>
      </b-col>
    </b-row>
    <b-card no-body class="mb-2 mt-2">
      <b-table
        id="scrolle-table"
        ref="refTable"
        class="position-relative"
        responsive
        :fields="fields"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="addedProducts"
      >
        <template #cell(comment)="data">
          {{ data.item.comment }}
        </template>
        <template #cell(product)="data">
          {{ data.item.product_combination.product_shortcut }}
        </template>
        <template #cell(price)="data"> {{ data.item.price }} DH</template>
        <template #cell(quantity)="data">
          {{ data.item.quantity }}
          {{ data.item.product_combination.unity.name }}
        </template>
        <template #cell(piece)="data"> {{ data.item.piece }} </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="deleteLine(data)"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </template>
      </b-table>

      <b-row class="mt-3">
        <b-col>
          <b-button
            :disabled="addedProducts.length === 0 || disabled"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="saveOrder"
          >
            {{ $t("Save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BInputGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { BFormDatepicker, BCardText } from "bootstrap-vue";
import ContextMenuSimple from "@/views/extensions/context-menu/ContextMenuSimple.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormDatepicker,
    vSelect,
    flatPickr,
  },

  directives: {
    Ripple,
  },
  async mounted() {
    try {
      this.loading = true;
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        instance.get("/parameters/shipping-types/"),
        /*instance.get("/parameters/cities/all/"),*/
        instance.get("/parameters/products/"),
        instance.get("/parameters/product-combinations-search/"),
        instance.get("/parameters/emergency-degrees/"),
        instance.get("/parameters/sellers/"),
      ]);
      this.clients = res[0].data;
      this.shippingTypes = res[1].data;
      /*this.cities = res[2].data;*/
      this.products = res[2].data;
      /*this.productCombinations = res[3].data.results;*/
      this.emergencyDegrees = res[4].data;
      this.sellers = res[5].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      this.loading = false;
    }
  },

  data() {
    return {
      loading: null,
      disabled : false,
      preparationtype: null,
      order_type: [
        { key: "True", label: this.$t("Street sale") },
        { key: "False", label: this.$t("Store sale") },
      ],
      fields: [
        { key: "product", label: this.$t("product") },
        { key: "piece", label: this.$t("piece") },
        { key: "quantity", label: this.$t("quantity") },
        { key: "price", label: this.$t("price") },
        { key: "comment", label: this.$t("Comment") },
        { key: "actions", label: this.$t("actions") },
      ],
      items: [],
      clients: [],
      annexes: [],
      shippingTypes: [],
      cities: [],
      products: [],
      selectedCity: null,
      selectedQuantity: null,
      selectedPiece: null,
      selectedProductCombination: null,
      selectedProduct: null,
      comment: null,
      selecteDeliveryDay: null,
      selectedClient: null,
      selectedShippingType: null,
      selectedAnnexe: null,
      emergencyDegree: null,
      emergencyDegrees: [],
      selectedAnnexes: [],
      productCombinations: [],
      callback: null,
      timeoutId: null,
      selectedPrice: null,
      selectedMinPrice: null,
      orderComment: null,
      addedProducts: [],
      error: null,
      Unity: null,
      Selling_Unity: null,
      type: 0,
      state:null,
      type_order : null,
      sellers : [],
      selectedSellerAgent : null,
      disableSelectAnnexe : false,
      disableSelectSeller : false,
      shippingTypeId : null,
    };
  },
  methods: {
    PositiveNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async saveOrder() {
      this.disabled=true;

      var emergencyDegree_id;
        const result = await this.$bvModal.msgBoxConfirm(
          this.$t("Are you sure you want to save the order?"),
          {
            title: "Alert",
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!result) {
          this.disabled=false;
          return;
        }
        if (this.emergencyDegree === null) {
          emergencyDegree_id = null;
        } else {
          emergencyDegree_id = this.emergencyDegree.id;
        }
        if(this.preparationtype.key === "True"){
          this.type_order = 'True';
          this.state='in_preparation';
        }else{
          this.type_order = 'False';
          this.state='received';
        }

        if(this.type_order === 'True'){
          if (!this.selectedAnnexe) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Client annexe is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
          if (!this.selectedSellerAgent) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Seller is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
        }
        if(this.type_order === 'False'){
          if(this.selectedShippingType.id === 2){
            if (!this.selectedAnnexe) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Alert"),
                text: this.$t("Client annexe is required"),
                icon: "InfoIcon",
                variant: "warning",
              },
            });
            this.disabled=false;
            return;
          }
          }
        }

        const data = JSON.stringify({
          client_id: this.selectedClient.id,
          client_annexe_id: this.selectedAnnexe ? this.selectedAnnexe.id : null,
          shipping_type_id: this.selectedShippingType.id,
          shipping_date: this.selecteDeliveryDay,
          type_order: this.type_order,
          comment: this.comment,
          emergency_degree_id: emergencyDegree_id,
          state : this.state,
          seller_agent:this.selectedSellerAgent ? this.selectedSellerAgent.id : null,
          products: Object.assign({}, this.addedProducts.map((e) => ({
            ...e,
            product_combination: e.product_combination.id,
          }))),
        });

        try {
          const res = await instance.post("/orders/1/", data);
          if(res.data.status === 1){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Success"),
                text: this.$t("The order has been created"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.selectedClient = null;
            this.selectedAnnexe = null;
            this.selectedShippingType = null;
            this.selectedProduct = null;
            this.selectedProductCombination = null;
            this.emergencyDegree = null;
            this.selecteDeliveryDay = null;
            this.preparationtype = null;
            this.selectedPiece = null;
            this.comment = null;
            this.addedProducts = [];
            this.disabled=false;
            this.selectedSellerAgent = null;
          }
          if(res.data.status === 0){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Error"),
                text: this.$t("An error has occurred. Please try again"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.disabled=false;
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.disabled=false;
        }
    },
    addProduct(bvModal) {
      bvModal.preventDefault();
      if (this.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t(this.error),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (!this.selectedProductCombination) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Select a product first"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (this.selectedProductCombination.selling_type.id !== 2) {
        if (!this.selectedQuantity) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The quantity is required"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          return;
        }
      }

      if (this.selectedProductCombination.selling_type.id === 2) {
        if (!this.selectedPiece && !this.selectedQuantity) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The piece or The quantity is required"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          return;
        }
      }
      if(this.selectedPrice===null){
        this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("Price cannot be null"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
        return;
      }

      if (
        this.addedProducts.filter(
          (e) => e.product_combination.id === this.selectedProductCombination.id
        ).length > 0
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The product already selected"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      this.addedProducts.push({
        product_combination: this.selectedProductCombination,
        quantity: this.selectedQuantity,
        price: this.selectedPrice,
        piece: this.selectedPiece,
        comment: this.orderComment,
      });
      this.saisieCommandHidden();
    },
    saisieCommandHidden() {
      this.selectedProduct = null;
      this.selectedProductCombination = null;
      this.selectedQuantity = null;
      this.selectedPrice = null;
      this.selectedMinPrice = null;
      this.selectedPiece = null;
      this.orderComment = null;
      this.productCombinations = [];
    },
    showSaisieCommandModal() {
      this.$refs["saisie-command"].show();
    },
    deleteLine(data) {
      this.addedProducts = this.addedProducts.filter(
        (e) => e.product_combination.id !== data.item.product_combination.id
      );
    },
    async clientSelected(client) {
      this.selectedAnnexe = null;
      if (!client) return;

      if (client.end_date) {
        const date = new Date(client.end_date);
        this.firstflatPickerConfig = {
          ...this.firstflatPickerConfig,
          minDate: date.setDate(date.getDate() + 1),
        };
      }
      try {
        const res2 = await instance.get("/parameters/clients-annexes/", {
          params: {
            all: true,
            client_id: this.selectedClient.id,
          },
        });
        this.annexes = res2.data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async selectedOrderType(type) {
      if (type.key === "True") {
        this.type = 1;
        this.selectedShippingType = this.shippingTypes[0];
        this.disableSelectSeller = false;
      } else {
        this.selectedShippingType = null;
        this.type = 0;
        this.disableSelectSeller = true;
        this.selectedSellerAgent = null;
      }
    },
    async disabledOrenabledAnnexe(shippingType) {
      if(shippingType.id === 1){
        this.disableSelectAnnexe = true;
        this.shippingTypeId = 1;
        this.selectedAnnexe = null;
      }else{
        this.disableSelectAnnexe = false;
        this.shippingTypeId = 2;
      }
    },
    setSelected(value) {
      if (!value) return;
      if (!this.selectedClient) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must select a client first."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (
        this.selectedProducts.filter((e) => e.id === this.selectedProduct.id)
          .length > 0
      ) {
        this.selectedProduct = null;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The product combination has already been selected"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.selectedProducts.push(this.selectedProduct);
      this.$refs["refTable"].refresh();
    },
    fetchProductDesignation(ctx, callback) {
      instance
        .put(`/parameters/product-combinations/${this.selectedProduct.id}`, {
          sub_profile: this.selectedClient.sub_profile.id,
        })
        .then((response) => {
          this.selectedProduct = null;
          this.callback = callback;
          this.items.push(response.data);

          this.quantities = [...this.quantities, "1"];
          callback(this.items);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    async fetchPrice(combinaison) {
      this.Unity = combinaison.unity.name;
      this.Selling_Unity = "DH" + "/" + combinaison.unity.name;
      this.selectedPrice = null;
      //this.selectedMinPrice = null;

      try {
        const res = await instance.get(`/parameters/combinaison_price/`, {
          params: {
            client_id: this.selectedClient.id,
            combinaison_id: combinaison.id,
          },
        });
        this.selectedPrice = res.data.price;
        if(res.data.price===null){
            this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("Un erreur has been occurred plaise contact the administrator"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async fetchProductCombinaison(product) {
      this.selectedProductCombination=null;
      try {
        const res = await instance.get(`/parameters/product-combinations-id/`, {
          params: {
            id: product.id,
          },
        });
        this.productCombinations = res.data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: "Select product",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    /*fetchProductsOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get(
            "/parameters/product-combinations-search/",
            {
              params: {
                search,
              },
            }
          );
          this.productCombinations = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },*/
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
h6.etoile {
  position: absolute;
  top: 0;
}
label#client__BV_label_ {
  margin-left: 8px;
}
label#delivery__BV_label_ {
  margin-left: 8px;
}
label#type__BV_label_ {
  margin-left: 8px;
}
label#shipping__BV_label_ {
  margin-left: 8px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
